<template>
  <div>
    <SaleForm
      ref="saleForm"
      :operation="operation"
      :id="this.$route.params.id"
      @update="loadSale()"
    />
  </div>
</template>

<script>
import SaleForm from "@/components/sales/forms/SaleForm.vue";

export default {
  components: { SaleForm },

  data: () => ({
    operation: {
      id: 1,
      description: "Venda",
      movement_stock: "1",
      movement_stock_type: "out",
      movement_finance: "1",
      movement_finance_type: "in",
      icon: "mdi-cart-plus",
    },
  }),

  created() {
    this.$store.commit("saleForm/reset");
  },

  async mounted() {
    await this.loadSale();
  },

  methods: {
    loadSale() {
      if (this.$route.params.id) {
        this.$store.dispatch("saleForm/loadSale", this.$route.params.id);
      }

      if (this.$route.query.copy_sale) {
        // todo review this
        //this.$store.dispatch("saleForm/copySale", this.$route.query.copy_sale);
      }
    },
  },
};
</script>

<style>
</style>